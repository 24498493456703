var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingDetail.detail,
          expression: "loadingDetail.detail"
        }
      ],
      ref: "appForm",
      attrs: {
        model: _vm.appForm,
        rules: _vm.rules,
        "label-width": "170px",
        "label-suffix": _vm.constants.labelSuffix
      }
    },
    [
      _c(
        "ics-page-inner",
        { attrs: { title: "管理员账号信息" } },
        [
          !_vm.editView()
            ? _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-form-item", { attrs: { label: "企业类型" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.statusFormat(
                            _vm.appForm.appKey,
                            "companyType"
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.editView()
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "appKey", label: "企业类型" } },
                    [
                      _c(
                        "ics-item-inner",
                        {
                          attrs: {
                            prop: _vm.appForm.appKey,
                            format: function(val) {
                              return _vm.utils.statusFormat(val, "companyType")
                            }
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择企业类型",
                                filterable: ""
                              },
                              on: { change: _vm.changeCompany },
                              model: {
                                value: _vm.appForm.appKey,
                                callback: function($$v) {
                                  _vm.$set(_vm.appForm, "appKey", $$v)
                                },
                                expression: "appForm.appKey"
                              }
                            },
                            _vm._l(
                              _vm._.filter(_vm.constants.companyType, {
                                states: 1
                              }),
                              function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }
                            ),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("el-col"),
          !_vm.editView()
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "所属企业" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(_vm.appForm.companyName)
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.editView()
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "companyCode", label: "所属企业" } },
                    [
                      _c(
                        "ics-item-inner",
                        {
                          attrs: {
                            prop: _vm.appForm.companyCode,
                            format: function(val) {
                              return _vm.utils.statusFormat(val, "companyType")
                            }
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择所属企业名称/编号",
                                filterable: ""
                              },
                              on: {
                                change: function(val) {
                                  return _vm.companyChange(val)
                                }
                              },
                              model: {
                                value: _vm.appForm.companyCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.appForm, "companyCode", $$v)
                                },
                                expression: "appForm.companyCode"
                              }
                            },
                            _vm._l(_vm.notAccessFirmList, function(item) {
                              return _c("el-option", {
                                key: item.companyCode,
                                attrs: {
                                  label:
                                    item.companyName +
                                    "（" +
                                    item.companyCode +
                                    "）",
                                  value: item.companyCode
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "统一社会信用代码" } }, [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.utils.isEffectiveCommon(_vm.appForm.socialNo))
                  )
                ])
              ])
            ],
            1
          ),
          _vm.appForm.appKey === "finance-frame-core"
            ? _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否进行风控" } },
                    [
                      _c(
                        "ics-item-inner",
                        {
                          attrs: {
                            prop: _vm.appForm.riskFlag,
                            format: _vm.utils.isEffectiveCommon
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: !_vm.editView() },
                              model: {
                                value: _vm.appForm.riskFlag,
                                callback: function($$v) {
                                  _vm.$set(_vm.appForm, "riskFlag", $$v)
                                },
                                expression: "appForm.riskFlag"
                              }
                            },
                            _vm._l(_vm.constants.isNeedRisk, function(item) {
                              return _c(
                                "el-radio",
                                {
                                  key: item.value,
                                  attrs: { label: item.value }
                                },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "userName", label: "姓名" } },
                [
                  _c(
                    "ics-item-inner",
                    {
                      attrs: {
                        prop: _vm.appForm.userName,
                        format: _vm.utils.isEffectiveCommon
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入姓名" },
                        model: {
                          value: _vm.appForm.userName,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "userName", $$v)
                          },
                          expression: "appForm.userName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "phone", label: "手机号码" } },
                [
                  _c(
                    "ics-item-inner",
                    {
                      attrs: {
                        prop: _vm.appForm.phone,
                        format: _vm.utils.isEffectiveCommon
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入手机号码" },
                        model: {
                          value: _vm.appForm.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "phone", $$v)
                          },
                          expression: "appForm.phone"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "email", label: "个人邮箱" } },
                [
                  _c(
                    "ics-item-inner",
                    {
                      attrs: {
                        prop: _vm.appForm.email,
                        format: _vm.utils.isEffectiveCommon
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入个人邮箱" },
                        model: {
                          value: _vm.appForm.email,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "email", $$v)
                          },
                          expression: "appForm.email"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "idCardNo", label: "身份证号码" } },
                [
                  _c(
                    "ics-item-inner",
                    {
                      attrs: {
                        prop: _vm.appForm.idCardNo,
                        format: _vm.utils.isEffectiveCommon
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入身份证号码" },
                        model: {
                          value: _vm.appForm.idCardNo,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "idCardNo", $$v)
                          },
                          expression: "appForm.idCardNo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "个人信息备注" } },
                [
                  _c(
                    "ics-item-inner",
                    {
                      attrs: {
                        prop: _vm.appForm.remark,
                        format: _vm.utils.isEffectiveCommon
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入个人信息备注(限300字)",
                          maxlength: 300,
                          type: "textarea"
                        },
                        model: {
                          value: _vm.appForm.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "remark", $$v)
                          },
                          expression: "appForm.remark"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.viewEdit()
            ? _c("ics-button-inner", {
                attrs: { loading: _vm.loading.submit },
                on: { submit: _vm.submitForms }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }