<template>
  <el-main>
    <page-title show-back-btn />
    <div class="partition-area">
      <ics-admittance-inner :access-user-detail="accessUserDetail" @submit="submitForms" />
    </div>
  </el-main>
</template>

<script>
import IcsAdmittanceInner from './components/admittance-inner'
import { routeEnterMixin } from '@/assets/js/mixins'
export default {
  components: { IcsAdmittanceInner },
  mixins: [routeEnterMixin],
  data () {
    return {
      id: this.$route.query.id,
      accessUserDetail: {}
    }
  },
  created () {
    if (this.id) {
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.loading.detail = true
      this.api.admittance.getAccessUserDetail(this.id).then(result => {
        const data = result.data.data || {}
        data.appKey = data.firmType
        data.companyCode = data.firmCode
        data.companyName = data.firmName
        data.socialNo = data.firmSocialNo
        data.riskFlag = data.riskFlag
        data.userName = data.name
        data.phone = data.mobile
        this.accessUserDetail = data
      }).finally(() => {
        this.loading.detail = false
      })
    },
    submitForms  (formData) {
      return new Promise((resolve, reject) => {
        this.loading.submit = true
        let data = formData || {}
        let api = this.api.admittance.addAccessUser
        if (this.id) {
          api = this.api.admittance.updateAccessUser
        }
        api(data).then(result => {
          if (result.data.success === true) {
            this.loading.submit = false
            this.$message.success('保存成功')
            this.$router.back()
          } else {
            this.$message.error(result.data.message)
          }
          resolve(result.data.data)
        }).catch(e => {
          reject(e)
        }).finally(() => {
          this.loading.submit = false
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
