<template>
  <el-form ref="appForm" v-loading="loadingDetail.detail" :model="appForm" :rules="rules" label-width="170px" :label-suffix="constants.labelSuffix">
    <ics-page-inner title="管理员账号信息">
      <el-col v-if="!editView()" :span="24">
        <el-form-item label="企业类型">
          <p>{{ utils.statusFormat(appForm.appKey, 'companyType') }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="editView()" :span="12">
        <el-form-item prop="appKey" label="企业类型">
          <ics-item-inner :prop="appForm.appKey" :format="(val)=>utils.statusFormat(val,'companyType')">
            <el-select v-model="appForm.appKey" placeholder="请选择企业类型" filterable @change="changeCompany">
              <el-option v-for="item in _.filter(constants.companyType,{states:1})" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col />
      <el-col v-if="!editView()" :span="12">
        <el-form-item label="所属企业">
          <p>{{ utils.isEffectiveCommon(appForm.companyName) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="editView()" :span="12">
        <el-form-item prop="companyCode" label="所属企业">
          <ics-item-inner :prop="appForm.companyCode" :format="(val)=>utils.statusFormat(val,'companyType')">
            <el-select v-model="appForm.companyCode" placeholder="请选择所属企业名称/编号" filterable @change="(val)=>companyChange(val)">
              <el-option v-for="item in notAccessFirmList" :key="item.companyCode" :label="`${item.companyName}（${item.companyCode}）`" :value="item.companyCode" />
            </el-select>
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="统一社会信用代码">
          <p>{{ utils.isEffectiveCommon(appForm.socialNo) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="appForm.appKey === 'finance-frame-core'" :span="24">
        <el-form-item label="是否进行风控">
          <ics-item-inner :prop="appForm.riskFlag" :format="utils.isEffectiveCommon">
            <el-radio-group v-model="appForm.riskFlag" :disabled="!editView()">
              <el-radio v-for="item in constants.isNeedRisk" :key="item.value" :label="item.value">
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="userName" label="姓名">
          <ics-item-inner :prop="appForm.userName" :format="utils.isEffectiveCommon">
            <el-input v-model="appForm.userName" placeholder="请输入姓名" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="phone" label="手机号码">
          <ics-item-inner :prop="appForm.phone" :format="utils.isEffectiveCommon">
            <el-input v-model="appForm.phone" placeholder="请输入手机号码" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="email" label="个人邮箱">
          <ics-item-inner :prop="appForm.email" :format="utils.isEffectiveCommon">
            <el-input v-model="appForm.email" placeholder="请输入个人邮箱" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="idCardNo" label="身份证号码">
          <ics-item-inner :prop="appForm.idCardNo" :format="utils.isEffectiveCommon">
            <el-input v-model="appForm.idCardNo" placeholder="请输入身份证号码" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="个人信息备注">
          <ics-item-inner :prop="appForm.remark" :format="utils.isEffectiveCommon">
            <el-input v-model="appForm.remark" placeholder="请输入个人信息备注(限300字)" :maxlength="300" type="textarea" />
          </ics-item-inner>
        </el-form-item>
      </el-col>
      <ics-button-inner v-if="viewEdit()" :loading="loading.submit" @submit="submitForms" />
    </ics-page-inner>
  </el-form>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import {isIdentityId} from '../../../components/template/validate'
export default {
  name: 'IcsAdmittanceInner',
  mixins: [routeEnterMixin],
  props: {
    loadingDetail: {
      type: Object,
      default () {
        return {}
      }
    },
    accessUserDetail: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    const validatePhone  = (rule, value, callback) => {
      if (/^\d{3}-\d{8}|\d{4}-\d{7,8}$/.test(value) || /^1[3|4|5|7|8|9][0-9]\d{8}$/.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的联系电话'))
      }
    }
    const checkIdentitytionId  = (rule, value, callback) => {
      let errorMsg = isIdentityId(value);
      if (errorMsg != '') {
        callback(new Error(errorMsg));
      } else {
        callback()
      }
    }
    const checkData = (rule, value, callback) => {
      if (value) {
        // eslint-disable-next-line no-useless-escape
        if (/[^\w\.\/]/ig.test(value)) {
          callback(new Error('请不要输入中文和空格！'))
        } else {
          callback()
        }
      }
      callback()
    }
    const checkEmail = (rule, value, callback) => {
      if (value) {
        if (/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
          callback()
        } else {
          callback(new Error('邮箱格式不正确'))
        }
      }
      callback()
    }
    return {
      appForm: {
        appKey: '',
        companyCode: '',
        companyName: '',
        socialNo: '',
        riskFlag: 0,
        userName: '',
        phone: '',
        email: '',
        idCardNo: '',
        remark: ''
      },
      notAccessFirmList: [],
      rules: {
        appKey: [
          { required: true, message: '请选择企业类型', trigger: 'change' }
        ],
        companyCode: [
          { required: false, message: '请选择企业名称', trigger: 'change' }
        ],
        userName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { max: 50, message: '姓名最多允许输入50个字符', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入个人邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        idCardNo: [
          { required: true, message: '请输入法人身份证号码', trigger: 'blur' },
          { validator: checkIdentitytionId , trigger: "blur" }
        ]
      }
    }
  },
  watch: {
    accessUserDetail: 'initData'
  },
  created () {
    if (this.viewEdit()) {
    }
  },
  methods: {
    initData(value) {
      this.appForm = Object.assign(this.appForm, this.utils.filterTrimValue(value))
    },
    editView () {
      if (this.$route.params.editMode === 'edit') {
        return false
      } else {
        return true
      }
    },
    changeCompany (val) {
      this.appForm.companyCode = ''
      this.appForm.socialNo = ''
      if (val === 'finance-frame-customer') {
        this.getNotAccessFirm(val)
      } else if (val === 'finance-frame-capital') {
        this.getNotAccessFirm(val)
      } else if (val === 'finance-frame-core') {
        this.getNotAccessFirm(val)
      }
    },
    getNotAccessFirm (appKey) {
      let data = []
      this.api.admittance.getNotAccessFirm(appKey).then(result => {
        result.data.data.forEach(item => {
          data.push({
            companyCode: item.companyCode,
            companyName: item.companyName,
            socialNo: item.socialNo,
            coreSocialNo: item.coreSocialNo,
            coreCode: item.coreCode,
            coreId: item.coreId,
            coreName: item.coreName
          })
        })
      })
      this.notAccessFirmList = data
    },
    companyChange (val) {
      const data = this._.find(this.notAccessFirmList, { companyCode: val }) || {}
      const { companyName, socialNo } = data
      this.appForm = this._.assign(this.appForm, { companyName, socialNo })
    },
    submitForms () {
      this.$refs.appForm.validate().then(() => {
        if (this.appForm.appKey !== 'finance-frame-core') {
          delete this.appForm.riskFlag
        }
        this.$emit('submit', this.appForm)
      })
    }
  }
}
</script>

<style scoped>

</style>
